@import "../../styles/mixins/breakPoints.scss";

.pagination_container {
    display: flex;
    flex-direction: row;
    /*width: 100%;*/
    padding: 20px 0;

    //width: fit-content;

    width: auto !important;
    justify-content: center;
    align-items: center !important;

    @include mobile-and-less {
        margin: 0;
    }
}

.pagination_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--surface);


    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;

    /* identical to box height */
    text-align: center;

    color: #141414;
}

.pagination_button.full {
    background-color: var(--color-secondary);
}

.pagination_button.pagination_dots {
    border: none;
    cursor: default;
}
