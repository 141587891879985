

.center-contact-form {
    display: flex;
    flex-direction: column;
    background-color: var(--surface);
    border-radius: var(--border_radius);
    padding: 20px;
    width: 500px;
    max-width: 90vw;

    form {
        margin-top: 10px;
        margin-bottom: 20px;
        width: 100%;

        & > * {
            margin-top: 10px;
        }

        .row {
            display: flex;
            flex-direction: row;

            & > * + * { margin-left: 10px;}
        }
    }
}