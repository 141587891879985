.no-access-message {
    display: flex;
    flex-direction: column;

    p {
      margin: 13px auto 0;
    }

    &__button {
      margin: 20px auto;
    }
  }