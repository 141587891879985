@import "../../styles/mixins/breakPoints.scss";

.category-selection {
    width: 100%;
    display: flex;

    @include mobile-and-less {
        flex-direction: column;
    }

    &__tab {
        flex: 1;
        width: 100%;
        max-width: unset !important;
    }
}
