@mixin mobile-and-less {
    @media screen and (max-width: (950px)) {
        @content;
    }
}

@mixin bigger-than-mobile {
    @media screen and (min-width: (951px)) {
        @content;
    }
}

@mixin large-screen {
    @media screen and (min-width: (1199px)) {
        @content;
    }
}
