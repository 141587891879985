@import "../../styles/mixins/breakPoints.scss";

.yield-calculator {
    &__screen-container {
        width: 50%;

        margin: auto;
        @include mobile-and-less {
            width: 100%;
        }
    }

    &__no-access {

        display: flex;
        flex-direction: column;

        p {
            margin: 13px auto 0;
        }

        &__button {
            margin: 20px auto;
        }
    }
}
