@import '../../../styles/variables';

.description-detail {
  display: flex;

  flex-direction: column;

  background-color: white;

  padding: 26px 29px;
  margin: 13px 0 44px;

  border-radius: $large_border_radius;

  width: 100%;

  .files-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  h3 {

  }

  .agency-contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-top: 40px;
    background-color: #fff;
    box-shadow: var(--box_shadow);
    padding: 10px 15px;
    border-radius: var(--border_radius);

    .agency-data,
    .agency-actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      
      button {
        margin: 0;
        width: 100%;

        &:last-child {
          margin-top: 10px;
        }
      }
    }

    .agency-data {
      margin-right: 10px;
      img {
        margin-bottom: 10px;
        width: 120px;
        height: 120px;
        object-fit: cover;
      }
    }

    @media (max-width: 500px) {
      flex-direction: column;
      width: 100%;

      .agency-data {
        margin-right: unset;
        margin-bottom: 40px;
      }

      .agency-actions {
        width: 100%;
      }
    }
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;

    margin-top: 6px;
  }

  &__anchor {
    display: block;

    margin-top: 10px;

    font-size: 15px;
    font-weight: 700;
    line-height: 17px;

    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  &__more-infos-button {
    margin-top: 16px;
  }

  &__anomaly-button {
    margin-top: 39px;

    width: fit-content;

    background-color: transparent;

    //outline: 2px solid var(--color-secondary);
  }

  &__list {
    margin: 39px -19px 0 -19px; // compensate for li elements start and end margins

    position: relative;

    &__divider {
      position: absolute;

      width: 100%;
      height: 100%;

      top: 1px;
      left: 0;

      transform: translateX(50%);

      border-left: 2px solid #e5e5e5;

      margin-left: -1px;
    }

    ul {
      columns: 20em auto;// this create items in columns
      column-rule: 2px solid #e5e5e5;

      font-size: 15px;
      line-height: 17px;

      li {

        display: flex;
        justify-content: space-between;

        span:first-child {
          font-weight: 700;

          margin-left: 19px;
        }

        span:last-child {
          color: #646464;

          margin-right: 19px;
        }
      }
    }
  }
}
