@import "../../styles/mixins/breakPoints.scss";

.IaIntegrationsScreen {
    &__screen-container {
        width: 50%;

        margin: auto;
        @include mobile-and-less {
            width: 100%;
        }
    }
}
