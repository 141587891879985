

#register {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 80px 20px;

    form {
        max-width: 500px;
        
        > *  {
            margin-top: 20px;
        }
    }
}

#register-redirect {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
    min-height: calc(100vh - 40px);

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: var(--border_radius);
        background-color: var(--surface);
        box-shadow: var(--box_shadow);
        padding: 20px 40px;
        text-decoration: none;
        font-size: 1.4rem;
        font-weight: bold;
        cursor: pointer;
        max-width: 80vw;
        color: var(--secondary);

        &:first-child {
            margin-bottom: 20px;
        }
    }
}