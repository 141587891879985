.modal-content {
    position: relative;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 80%;
    max-width: 400px;

    background-color: white;

    padding: 30px;

    border-radius: 8px;

    box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.25),
        0px 10px 10px rgba(0, 0, 0, 0.22);

    &:focus {
        outline: "none";
    }

    &__title {
        // margin-top: 8px;

        font-size: 1.1em;
    }

    &__text {
        margin-top: 8px;
    }

    &__mail-container {
        margin-top: 24px;
    }

    &__buttons-container {
        margin-top: 24px;

        display: flex;
        justify-content: center;
        gap: 16px;
    }
}
