.immo-button {
  width: fit-content;
  height: 35px;

  padding: 0 23px;

  border-radius: 999px;

  border: none;

  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 14.95px;
  text-decoration: none;

  color: #141414;

  &--primary {
    background: var(--color-secondary);

    &-outlined {
      background-color: transparent;

      outline: 2px solid var(--color-secondary);
    }
  }

  &--div {
    padding-top: .8em;
  }

  &:hover {
    cursor: pointer;
  }
}
