@import "../../styles/mixins/breakPoints";

.input-data {
    margin-top: 50px;
    min-height: 60vh;
    display: flex; // Ajout pour centrer le bloc de prédiction
    flex-direction: column; // Ajout pour centrer le bloc de prédiction
    align-items: center; // Ajout pour centrer le bloc de prédiction
}

.filter-block-header {
    min-height: 3em;

    display: flex;
    align-items: flex-end;

    @include mobile-and-less {
        justify-content: center;
    }

    padding-bottom: .4em;
}

.filter-block-header h2 {
    font-size: 1.2em; // réduire la taille des titres

    text-align: center; // centrer les titres
    //margin-bottom: 10px; // ajouter un espace entre le titre et le champ
}

.filter-block {
    margin-bottom: 20px; // pour ajouter un espace entre les blocs
}

.filter-block .MuiTextField-root {
    width: 100%; // pour que les champs prennent toute la largeur du bloc
}

.filter-block .MuiFormControl-root {
    width: 100%; // pour que les menus déroulants prennent toute la largeur du bloc
}


.predict-button {
    background-color: #FFC802; // couleur de fond du bouton
    border: none;
    color: black; // couleur du texte du bouton
    padding: 15px 32px; // taille du bouton
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px; // pour rendre les coins du bouton arrondis
    transition: background-color 0.3s; // pour une transition en douceur lors du survol
}

.predict-button:hover {
    background-color: #e1b100fe; // changer la couleur de fond du bouton lors du survol
}

.prediction-result {
    margin-top: 50px; // pour ajouter un espace en haut
    width: 80%; // pour contrôler la largeur du bloc
    padding: 20px; // pour ajouter de l'espace à l'intérieur du bloc
    border: 1px solid #ddd; // pour ajouter une bordure autour du bloc
    border-radius: 4px; // pour rendre les coins du bloc arrondis
    background-color: #f9f9f9; // pour changer la couleur de fond du bloc
    text-align: center; // pour centrer le texte à l'intérieur du bloc
}

.prediction-result h2 {
    font-size: 1.5em; // pour contrôler la taille du titre
    margin-bottom: 10px; // pour ajouter un espace entre le titre et le texte
}

.prediction-result p {
    font-size: 1.2em; // pour contrôler la taille du texte
    color: #333; // pour changer la couleur du texte
}
