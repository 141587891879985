

#user-reports {
    display: flex;
    flex-direction: column;

    .report-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        > * + * { margin-top: 20px;}
    }

    .report-elem {
        display: flex;
        flex-direction: column;
        width: 500px;
        max-width: 100%;
        background-color: var(--surface);
        box-shadow: var(--box_shadow);
        border-radius: var(--border_radius);
        padding: 10px;

        .report-elem-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .status-container {
                display: flex;
                flex-direction: row;

                .report-bubble {
                    background-color: var(--primary);
                    padding: 2px 4px;
                    border-radius: 4px;
                    color: var(--on_primary);
                    box-shadow: var(--box_shadow);

                    &:first-child {
                        margin-right: 10px;
                    }

                    &.unhandled {
                        background-color: var(--error);
                    }

                    &.yet-sold {
                        background-color: var(--markdown_note_bg);
                    }

                    &.price {
                        background-color: var(--markdown_error_bg);
                    }
                }
            }
        }

        .date {
            display: block;
            margin-top: 5px;
            margin-bottom: 10px;
        }
    }
}