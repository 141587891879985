@import "../../styles/mixins/breakPoints.scss";

.market-data-card {
  &__container,
  &__container--full-screen {
    box-shadow: 0 4px 8px rgba(0.3, 0.3, 0.3, 0.4);

    z-index: 100;

    background-color: white;

    max-width: 100vw;
    width: 300px;

    border-radius: 10px;

    overflow: hidden;

    outline: 1px solid var(--color-secondary);
  }

  &__container {
    position: absolute;
    bottom: 2rem;
    right: calc(100% + 2rem);

    @include mobile-and-less {
      position: fixed;
      right: 2rem;
    }
  }

  &__container--full-screen {
    position: fixed;
    right: 16px;
    bottom: 16px;
  }

  &__header {
    position: relative;

    &__close-button {
      position: absolute;

      display: flex; /* Ajout pour activer flexbox */
      justify-content: center; /* Centre horizontalement */
      align-items: center; /* Centre verticalement */

      background-color: white;

      right: .75rem;
      top: .75rem;

      width: 1.75rem;
      height: 1.75rem;

      border-radius: 1rem;

      color: black;
      border: none;

      cursor: pointer;

      &:hover {
        background-color: var(--color-secondary);
      }

      &__icon {
        font-size: 2rem;
      }


    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;

      &__container {
        height: 260px;
      }
  }

  &__main {
    height: 16rem;

    padding: 1rem;

    overflow-y: auto;

    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 1.25rem;

    margin-bottom: .5rem;
  }

  &__rent {
    font-weight: bold;
    margin-bottom: .5rem;
  }

  &__description {
    box-sizing: border-box;

    //overflow-y: auto;

    word-wrap: break-word;
    hyphens: auto;
  }
}