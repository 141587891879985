.adv-filters-container {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding:26px 56px 27px 39px;

    background: #141414;
    border-radius: 20px;

    .selectors {
        display: flex;
        gap:20px;

        @media (max-width: 950px) {
            flex-direction: column;
        }

        .selector {
            display:flex;
            flex-direction: column;
            gap:8px;
        }

        .filter-title {

            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 17px;

            color: #FFFFFF;
        }
        .filter-dropdown {
            width: 145px;
            height: 46px;

            background: #ECECEC;
            border-radius: 999px;


            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 17px;

            color: rgba(20, 20, 20, 0.5);
            text-align: center;
            border: none;
        }
        .filter-fields {
            display: flex;
            align-items: center;
            gap: 8px;
        }
        input {
            width: 104px;
            height: 46px;

            background: #ECECEC;
            border-radius: 999px;


            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 17px;

            color: rgba(20, 20, 20, 0.5);
            text-align: center;
            border: none;
        }
        // hide arrows in Chrome, Safari, Edge, Opera
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        // hide arrows in firefox
        input[type=number] {
            -moz-appearance: textfield;
        }

        .sep {
            width: 18px;
            height: 3px;

            background: rgba(255, 255, 255, 0.5);
            border-radius: 16px;
        }
    }

    .apply {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 243px;
        height: 56px;

        background: #FFFFFF;
        border-radius: 999px;
        border: none;


        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 17px;

        color: #141414;

    }
    .close {
        position: absolute;
        right: 22px;
        top: 22px;
        cursor: pointer;
    }
}
