@import "../../../styles/mixins/breakPoints.scss";

.property-selection {
    width: 1040px;

    @include mobile-and-less {
        width: 100%;

        .pagination_container {
            margin: auto;
        }
    }

    &__tab {
        margin-bottom: 2em;

        // outline: 1px solid var(--color-secondary);
        border-radius: 0 0 0.5em 0.5em;

        padding: 1em;

        @include mobile-and-less {
            margin-top: 0;
            padding-top: 0;
        }

        .properties-list {
            margin-top: 0;
            // padding: 1em;
        }
    }

    &__empty {
        padding: 3em;

        &__image {
            width: 100%;

            margin-bottom: 2em;
        }

        h3,
        p {
            text-align: center;
            line-height: 1.5;
        }
    }

    &__tools {
        order: 1;

        @include mobile-and-less {
            order: 0;

            visibility: hidden;
            width: 0;
            height: 0;

            overflow: hidden;
        }

        display: flex;

        align-items: center;
    }

    &__left-container {
        display: flex;
        //align-items: center;
        flex: 1;
        justify-content: space-between !important;
        align-items: center !important;

        margin-right: 32px;

        @include mobile-and-less {
            margin: 0;
        }
    }

    &__pagination-sort-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .map-button {
            height: 3rem;

            margin-right: 2em;

            @include mobile-and-less {
                margin-right: 3.5em;
            }

            z-index: 0;
        }

        .pagination_container {
            width: 100%;

            //order: 0;
            //
            //@include mobile-and-less {
            //    order: 1;
            //}
        }

        @include mobile-and-less {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .pagination_container {
                width: auto;
            }

            .sort_div {
                order: 0;
            }

            .sort-select {
                margin-top: 16px !important;
            }
        }

        p {
            white-space: nowrap;
            line-height: 1em;
        }
    }
}
