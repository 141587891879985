#map-container {
    overflow-anchor:none;
}

#main-map {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;

    .list-button {
        position: absolute;
        top: 60px;
        left: 10px;
        background-color: #fff;
        z-index: 10;
        box-shadow: var(--box_shadow);
        border-radius: var(--border_radius);
        padding: 6px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        svg {
            font-size: 1.2rem;
            margin-right: 10px;
        }

        span {
            font-size: 1.2rem;
        }
    }

    .absolute-property {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 10px;
        width: 100%;

        .relative-one {
            position: relative;
            max-width: 90%;

            .close-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 10px;
                right: 10px;
                background-color: #fff;
                box-shadow: var(--box_shadow);
                border: 2px solid var(--primary);
                width: 24px;
                height: 24px;
                border-radius: 12px;
                z-index: 100;
                cursor: pointer;

                svg {
                    font-size: 16px;
                }
            }
            
        }

        a {
            text-decoration: none;
        }

        li {
            list-style: none;
        }

        .property-element {
            
            &__content {
                background-color: #fff !important;
                flex-direction: row;
                width: unset;
                height: 180px;
                padding: 0;
                margin: 0;
            }

            &__infos {
                min-width: 180px;
            }

            &__infos__main {
                margin: 0;
                margin-top: 5px;
            }

            &__gross-yield__percent {
                font-size: 1.2rem;
                margin: 0;
            }

            &__gross-yield__text {
                margin-top: 5px;
            }

            &__publication-date {
                display: none;
            }
        }
    }
}