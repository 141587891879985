

#home-slider {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: min-content;
    width: 1200px;
    max-width: calc(100% - 80px);

    .arrow-container {
        position: absolute;
        z-index: 15;
        background-color: #fff;
        box-shadow: var(--box_shadow);
        width: 32px;
        height: 32px;
        display: flex;
        left: -10px;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--primary);
        border-radius: 16px;
        cursor: pointer;

        svg {
            fill: var(--primary);
            font-size: 20px;
        }

        &.forward {
            left: unset;
            right: -10px;
        }

        &.hide {
            display: none;
        }
    }

    .selection-slider {
        display: flex;
        width: 1200px;
        max-width: 100%;
        overflow: hidden;
        margin: 20px 0 20px 0;
        padding-left: 40px;
        position: relative;
        align-items: center;

        &::after {
            display: block;
            content: "";
            height: 100%;
            width: 40px;
            background: linear-gradient(0.25turn, rgba(0, 0, 0, 0), #fff);
            position: absolute;
            right: 0;
            z-index: 10;
        }
    
        &::before {
            display: block;
            content: "";
            height: 100%;
            width: 40px;
            background: linear-gradient(0.25turn, #fff, rgba(0, 0, 0, 0));
            position: absolute;
            left: 0;
            z-index: 10;
        }
    
        .selection-slider-content {
            display: flex;
            flex-direction: row;
            transition: .3s;
    
            > * + * { margin-left: 20px;}
    
    
            li {
                list-style: none;
    
    
                a {
                    text-decoration: none;
                }
            }
        }
    }

    
}