.market-data {
    &__no-access {

        display: flex;
        flex-direction: column;

        p {
            margin: 13px auto 0;
        }

        &__button {
            margin: 20px auto;
        }
    }
}

#market-data-inner {
    height: 100%;
    background-color: #fff;
    overflow-y: auto;
    padding-right: 12px;

    max-width: 88vw; // magic number!

    .filter-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .filter-row {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: 100%;

            .filter-block {
                width: 100%;
                margin-bottom: 6px;

                h2 {
                    font-size: 1rem;
                    margin-bottom: 5px;
                }

                &:first-child {
                    margin-right: 10px;
                }
            }
        }
    }

    .show-data {
        margin-top: 20px;

        .data-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            padding: 10px 0;
    
            .data-show {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                background-color: #fff;
                box-shadow: var(--box_shadow);
                padding: 6px 12px;
                border-radius: var(--border_radius);
    
                .label {
                    color: var(--secondary_text);
                }
    
                .value {
                    font-weight: bold;
                    margin-top: 10px;
                    font-size: 1.2rem;
                }

                &:first-child {
                    margin-right: 10px;
                }
            }
        }
    }
}