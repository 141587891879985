@import "../../../styles/mixins/breakPoints.scss";

#main-filters {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    > * + * {
        @include bigger-than-mobile {
            margin-left: 10px;
        }

        @include mobile-and-less {
            margin-top: 5px;
        }
    }

    * {
        border-color: var(--divider);
    }

    @media (max-width: 700px) {
        flex-direction: column;
    }

    // adjust buttons on mobile view
    button {
        @include mobile-and-less {
            margin-top: 5px;
            margin-left: 0;
        }
    }

    .our-selection {
        padding: 0 16px;
        //margin-left: 16px;

        border: 2px solid var(--color-secondary);
        background-color: var(--color-surface);
        border-radius: 999px;

        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 17px;

        color: #141414;

        &.selected {
            background: var(--color-secondary);
        }
    }

    .peb-form {
        height: 40px;
        width: 100%;
        background-color: var(--surface);

        * {
            outline: none;
        }

        .peb-select {
            height: 40px;
            font-size: 0.875rem;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            letter-spacing: 0.02857em;
            outline: none;
        }
    }

    .selector {
        box-sizing: border-box;
        background-color: transparent;

        height: 48.87px;
        min-width: fit-content;

        border: 2.44356px solid #000000;
        border-radius: 999px;

        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        text-align: center;

        color: #000000;
    }

    a {
        text-decoration: none;
        width: 152.61px;
    }

    button,
    .peb-select {
        //height: 40px;
        //color: var(--text);
        background-color: var(--surface);

        max-width: 183px;
        height: 48.87px;
        border-radius: 999px;
        min-width: 138px;
        text-transform: none;
        letter-spacing: 0;

        font-family: "Helvetica", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;

        /* identical to box height */
        text-align: center;

        color: #000000;

        @media (max-width: 700px) {
            width: 100%;
            max-width: unset;
        }
    }
    .peb-select > div {
        min-height: unset;
    }

    a button {
        width: 100%;
    }

    // @media (max-width: 950px) {
    //     & {
    //         flex-wrap: wrap;
    //         height: 90px;

    //         a {
    //             margin-left: 0;
    //             margin-top: 10px;
    //             width: 100%;

    //             button {
    //                 width: 100%;
    //                 justify-content: center;
    //             }
    //         }

    //         .peb-form {
    //             width: calc(33% - 11px);
    //         }

    //         button {
    //             width: calc(33% - 11px);
    //         }
    //     }
    // }
}

.popper-home-filter-content {
    padding: 20px;
    border-radius: var(--border_radius);
    background-color: var(--surface);
    box-shadow: var(--box_shadow);

    > * + * {
        margin-top: 20px;
    }

    .value-header {
        display: flex;
        flex-direction: row;

        > * + * {
            margin-left: 10px;
        }
    }

    .value-validate {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        > * + * {
            margin-left: 10px;
        }
    }
}
