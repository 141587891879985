

.footer {
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    background: #141414;
    gap: 5%;
    .c {
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        gap:21px;

        span {
            margin: 0 5px;
        }

        a {
            text-decoration: none;
            margin: 0 5px;
            font-size: 14px;
            white-space: nowrap;
        }

        div {
            display: flex;
            flex-direction: column;
            gap:18px;
        }
    }

    .row {
        display: flex;
        flex-direction: row;
        width: 50%;
        //justify-content: space-between;

        .link-block {
            display: flex;
            flex-direction: column;
            margin: 0 10px;
            gap:11px;
            h4 {
                margin-bottom: 10px;
            }

            a {
                text-decoration: none;
                font-size: 14px;

                &:hover {
                    color: var(--primary);
                }
            }
            .separator {
                width: 33px;
                margin-left: 2px;

                border: 1px solid #FFFFFF;
            }
            .block {
                display: flex;
                flex-direction: row;
                gap:8px;
                .vertical-separator {
                    width: 0;
                    height: 35px;

                    border: 1px dashed #FFFFFF;
                }
                .side {
                    display: flex;
                    flex-direction: column;
                    gap:11px;
                }
            }
        }
    }

    .title {

        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;

        /* identical to box height */

        color: #FFFFFF;
    }

    .link-text {

        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        /* identical to box height */

        color: #FFFFFF;
    }

    @media (max-width: 900px) {
        flex-direction: column-reverse;

        .c {
            margin-top: 20px;
        }

        .row {
            width: 100%;
            justify-content: space-evenly;
        }
    }

    @media (max-width: 450px) {
        .row {
            flex-direction: column;
            .link-block {
                margin: 10px 0;
            }
        }

        .c {
            a {
                white-space: inherit;
            }
        }
    }

}
