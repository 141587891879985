.properties-list {
    display: grid;
    grid-template-columns: repeat(
        3,
        1fr
    ); /* creates 2 columns with equal width */
    grid-template-rows: repeat(2, 1fr); /* creates 2 rows with equal height */
    margin-top: 40px;
    gap: 40px;

    list-style-type: none;

    @media (max-width: 1000px) {
        grid-template-columns: repeat(
            2,
            1fr
        ); /* creates 2 columns with equal width */
    }

    @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0;
    }
}

// .properties-list a {
//     display: flex;
//     align-items: flex-start;
//     justify-content: center;
//     text-decoration: none;
//     color: var(--text);
//     transition: 0.3s;

//     &.loading {
//         opacity: 0.3;
//     }
// }

@media (max-width: 700px) {
    .main_container a {
        width: 100%;
    }
}
