html {
    --background: #fafafa;
    --surface: #ececec;
    --text: #000;
    --secondary_text: #505050;
    --on_surface: #000;
    --primary: rgb(236, 151, 18);
    --secondary: #3498db;
    --error: #b00020;
    --warning: #f39c12;
    --progress: #f7dc6f;
    --success: #7cb342;
    --success_transparent: rgba(124, 179, 66, 0.6);
    --on_primary: #fff;
    --on_primary_secondary: #d7dbdd;
    --on_error: #fff;
    --on_success: #fff;
    --border_radius: 6px;
    --divider: #d5dbdb;
    --divider_light: #eff2f2;
    --deactivate: #d5dbdb;
    --box_shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    --light_shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    --scroll_bar: #bfdeff;
    --transparent: rgba(255, 255, 255, 0.6);
    --transparent-heavy: rgba(255, 255, 255, 0.75);
    --color-primary: #141414;
    --color-secondary: #ffc802;
    --color-secondary-light: hsl(49, 80%, 85%);
    --color-secondary-transparent: rgba(255, 200, 2, 0.85);
    --color-divider-transparent: rgba(80, 80, 80, 0.85);
    --color-light-grey: #cccccc;
    --color-medium-grey: #bbb;

    --color-blue: hsl(203, 64%, 55%);
    --color-light-blue: hsl(203, 43%, 92%);

    --markdown_note_bg: #a2d9ce;
    --markdown_question_bg: #ccd1d1;
    --markdown_warning_bg: #f8c471;
    --markdown_error_bg: #ec7063;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    color: var(--text);
}

body {
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background);

    font-family: "Lato", "Arial", Helvetica, sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

div::-webkit-scrollbar {
    width: 8px;
    background: transparent;
    position: fixed;
    height: 8px;
}

div::-webkit-scrollbar-thumb {
    background: var(--scroll_bar);
    border-radius: 3px;
}

.main-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding-top: 100px;
}

.center-full-width {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}
