

.custom-marker-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
    box-shadow: var(--box_shadow);
    border-radius: var(--border_radius);
    padding: 4px 8px;
    transform: translateX(-50%) translateY(-50%);
    border: 2px solid #fff;
    cursor: pointer;

    span {
        font-weight: bold;
        font-size: 11px;
        color: var(--on_primary);
    }

    &.short {
        width: 12px;
        height: 12px;
        padding: 0;

        span {
            display: none;
        }
    }
}