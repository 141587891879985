@import "../../styles/mixins/breakPoints.scss";

.yield-calculator {
    width: calc(100% - 10px);

    &__container {
        margin: 0 auto;

        h2 {
            margin: 24px 0 16px;
        }
    }

    &__first-panel {
        display: flex;
    }

    &__input {
        margin: 16px 2rem 0 0 !important;

        width: 45%;
    }

    &__total {
        margin-top: 16px;

        span {
            font-weight: 900;
        }
    }
}
