.main_view--no-access {
  max-width: 500px;
  height: 72vh;

  margin: auto;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-bottom: 24px;
  }
}