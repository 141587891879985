

.main-search {
    display: flex;
    flex: row;
    position: relative;
    width:100%;
    max-width:600px;


    form {
        width: 100%;
        border-radius: var(--border_radius);
        padding: 5px 10px;
        background-color: var(--surface);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        min-height: 56.2px;

        border-radius: 999px;

        .selected-item {
            background-color: var(--deactivate);
            padding: 2px 4px;
            border-radius: 2px;
            cursor: pointer;
            transition: .3s;
            margin: 4px;

            &:hover {
                background-color: var(--error);
                span {
                    color: var(--on_primary)
                }
            }
        }

        input {
            min-width: 240px;
            border: none;
            outline: none;
            padding: 0 15px;
            background-color: var(--surface);

            max-width: 90%;
            height: 21px;

            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            //line-height: 21px;

            /* identical to box height */

            color: rgba(0, 0, 0, 0.5);
        }
    }

    .search-results {
        display: flex;
        position: absolute;
        flex-direction: column;
        width: 100%;
        background-color: var(--surface);
        border-radius: var(--border_radius);
        box-shadow: var(--box_shadow);
        top: 100%;
        max-height: 400px;
        overflow: auto;
        z-index: 10;

        .search-block {
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .clickable-block {
                display: flex;
                padding: 5px 20px;
                cursor: pointer;
                transition: .3s;

                &:hover {
                    background-color: var(--primary);

                    span {
                        color: var(--on_primary);
                    }
                }
            }

            &>span {
                display: flex;
                text-transform: uppercase;
                color: var(--secondary_text);
                padding: 10px 10px 5px 10px;
            }
        }
    }
}
