.home-page {
    min-height: 100vh;
    background: var(--background);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .home_center {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;
        padding-bottom: 40px;
        margin-bottom: 20px;

        

        h1 {
            font-size: 3rem;
            line-height: 3.2rem;
            max-width: 800px;
            text-align: center;
            color: var(--text);
        }

        .home {
            display: flex;
            z-index: 2;
            max-width: 800px;
            width: calc(100% - 120px);
            flex-direction: column;
            align-items: center;

            .subtitle {


                font-style: normal;
                font-weight: 400;
                font-size: 25px;
                line-height: 29px;

                /* identical to box height */

                color: #000000;

                text-align: center;
                margin-top: 50px;
                margin-bottom: 20px;
            }

            .title {


                font-style: normal;
                font-weight: 700;
                font-size: 43px;
                line-height: 49px;
                text-align: center;

                color: #000000;
                @media (max-width: 700px) {
                    font-size: 43px;
                }
            }

            .filters {
                max-width: 600px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                margin-top: 40px;

                .main-search {
                    margin-top: 20px;
                }

                #main-filters {
                    margin-top: 20px;
                }
            }

            .goSearch {
                margin-top: 60px;

                @media (max-width: 700px) {
                    width:100%;
                }
            }

            button {
                width: 425.18px;
                height: 68.42px;

                background: #141414;
                border-radius: 1220.56px;


                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 21px;
                text-transform: none;
                letter-spacing: 0;

                /* identical to box height */
                text-align: center;

                color: #FFFFFF;
            }

            .input_div {
                width: 100%;
                background-color: var(--surface);
                border: 1px solid var(--divider);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-top: 40px;
                border-radius: 8px;
                padding: 4px;

                input {
                    border: none;
                    outline: none;
                    width: 90%;
                    max-width: 500px;
                    padding: 0px 15px;
                    font-size: 16px;
                    //background-color: var(--surface)

                    background-color: red;
                }
            }


            .search_btn {
                background-color: var(--primary);
                padding: 5px 15px;
                border-radius: 8px;
                cursor: pointer;

                p {
                    color: var(--on_primary);
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }


        @media (max-width: 700px) {
            .home {
                width: 100%;
                min-width: unset;

                h1 {
                    min-width: unset;
                }
            }
        }

        @media (max-width: 500px) {
            & {
                padding-top: 0;
            }
            .home {
                h1 {
                    display: none;
                }
            }
        }

    }

    .separation {
        max-width: 1000px;
        width: 95vw;
        height: 2.85px;
        margin-bottom: 15px;
        margin-top: 35px;
        background: #000000;
    }

    .hook_text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        .title {
            width:100%;
            max-width: 616px;


            font-style: normal;
            font-weight: 700;
            font-size: 28.5315px;
            line-height: 316.99%;

            /* or 90px */
            text-align: center;

            color: #000000;
            margin-bottom: 10px;

            @media (max-width: 700px) {
                font-weight: 600;
                line-height: 200%;
                font-size: 26.5315px;
            }
        }

        .subtitle {
            width: 194px;
            height: 29px;


            font-style: normal;
            font-weight: 400;
            font-size: 28.5315px;
            line-height: 100%;

            /* identical to box height, or 29px */
            text-align: center;

            color: #000000;

            @media (max-width: 700px) {
                font-size: 24.5315px;
            }
        }
    }

    .spotlight {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FFFFFF;
        border-radius: 50px 50px 0px 0px;
        width:100%;
        
        .title {
            width: 223px;
            height: 25px;


            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 100%;

            /* identical to box height, or 25px */
            text-align: center;

            color: #000000;

            margin-top: 40px;
        }
    }

    .main_container {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* creates 2 columns with equal width */
        grid-template-rows: repeat(2, 1fr); /* creates 2 rows with equal height */
        margin-top: 40px;
        gap:40px;

        list-style-type: none;

        @media (max-width: 1000px) {
            grid-template-columns: repeat(2, 1fr); /* creates 2 columns with equal width */
        }

        @media (max-width: 700px) {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap:0;
        }
    }

    .main_container a {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        text-decoration: none;
        color: var(--text);
        transition: .3s;

        &.loading {
            opacity: .3;
        }
    }

    @media (max-width: 700px) {
        .main_container a {
            width: 100%;
        }
    }

    .pages_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 750px;
    }

    .sort_div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    @media (max-width: 700px) {
        .pages_header {
            width: 100%;
            flex-direction: column-reverse;
            align-items: flex-start;

            .sort_div {
                margin-left: 5px;
            }
        }
    }

    .sort_div p {
        font-size: 18px;
        margin-right: 10px;
    }

    .sort_div select {
        padding: 2px 5px;
        outline: none;
        border-color: var(--primary);
        border-radius: 4px;
        background-color: var(--surface);
    }

    @media (max-width: 850px) {
        .main_container a {
            width: 100%;
        }
        .pages_header {
            width: 100%;
            margin: 0;
        }
    }

    @media (max-width: 650px) {

        section {
            padding: 0 20px;
        }
    }

    @media (max-width: 520px) {

        & {
            padding: 0 20px 20px 20px;
        }

    }
}

.empty-search {

    width: 100%;
    padding: 60px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 40%;
        margin-right: 60px;
    }

    div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h3 {
            font-size: 1.2rem;
            margin-bottom: 10px;
        }
    }

    @media (max-width: 740px) {

        & {
            flex-direction: column;
            text-align: center;

            h3 {
                width: 100%;
            }

            img {
                margin-bottom: 20px;
                width: 80%;
                margin-right: 0;
            }
        }

    }
}
