

.main-log {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);

    .center-log {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: var(--background);
        border-radius: var(--border_radius);
        overflow: hidden;
        padding: 20px;
        max-width: 90vw;

        .log-header {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            h2 {
                font-size: 1.2rem;
            }

            i {
                cursor: pointer;
            }
        }

        .log-form {
            &>* {
                margin-top: 20px;
            }
        }
    }
}