.market-data-marker {
  //background-color: var(--color-secondary);

  background-color: var(--primary);
  box-shadow: 0 4px 8px rgba(0.3, 0.3, 0.3, 0.4);

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 0.5rem;

  //height: 2rem;
  //width: auto;
  //border-radius: .25rem;

  height: 1rem;
  width: 1rem;

  border-radius: .5rem;




  outline: 1.5px solid white;

  &__rent {

  }
}