

.edit-view {
    display: flex;
    flex-direction: row;
    
    .input-container {
        width: 100%;
        padding: 10px 30px;

        .input-block {
            margin-Top: 50px;
        }
        
        .input-block span  {
            font-weight: bold;
            font-size: 1.2rem;
            text-transform: capitalize;
            &.a {
                margin-left: 40px;
            }
            &.b {
                margin-left: 80px;
            }
        }
        
        .input-elem {
            display: flex;
            flex-direction: column;
            margin: 20px 0;
            
            .title{
                font-weight: bold;
                font-size: 1.2rem;
                margin-bottom: 15px;
            }
            &.a {
                margin-left: 40px;
            }
            &.b {
                margin-left: 80px;
            }
            &.c {
                margin-left: 120px;
            }

            .boolean-view {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .fileList{
                display: flex;
                flex-direction: column;
                .fileItem{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .file-name{
                        padding: 5px 15px;
                        margin-top: auto;
                        margin-bottom: auto;
                        font-size: 18px;
                    }
                }
            }
            
        }
    }
}

.dialog-container{
    display: flex;
    padding: 20px;
    flex-direction: column;
    .files-list{
        display: flex;
        flex-direction: column;
        padding:20px;
        padding-bottom: 0px;
        .file-item{
            padding: 10px 10px;
        }
    }
}