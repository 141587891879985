@import "../../../styles/variables";
@import "../../../styles/mixins/breakPoints";

.price-detail {
    background-color: white;

    display: flex;

    border-radius: $large_border_radius;

    padding: 14px 14px 13px 14px;
    margin-top: 16px;

    @include large-screen {
        padding: 14px 29px 13px 29px;

        margin-top: 28px;
    }

    font-weight: 700;

    @include mobile-and-less {
        background: none;

        width: 100%;

        padding: 0 30px 13px;
        margin-top: 0;
        margin-bottom: 20px;

        align-items: flex-end;
    }

    &__left {
        display: flex;

        padding: 5px 0;

        margin: auto 0;

        @include mobile-and-less {
            flex-direction: column;

            padding: 0;
        }

        &__price-container {
            @include bigger-than-mobile {
                padding-right: 15px;
            }

            @include large-screen {
                padding-right: 30px;
            }
        }

        &__tools {
            display: flex;
        }

        &__favorite,
        &__freeze {
            margin-right: 10px;

            button {
                padding-left: 0px;
            }

            @include mobile-and-less {
                margin-bottom: 12px;
            }

            @include large-screen {
                margin-right: 12px;
            }
        }

        &__rent-container {

            @include bigger-than-mobile {
                padding-left: 15px;

                border-left: 3px solid #00000080;
            }

            @include large-screen {
                padding-left: 30px;
            }
        }

        &__price-container,
        &__rent-container {
            @include mobile-and-less {
                padding: 0;
            }
        }

        &__price-label,
        &__rent-label {
            font-size: 15px;
            line-height: 17px;

            @include mobile-and-less {
                font-weight: 500;
            }
        }

        &__rent-label {
            display: flex;
            flex-wrap: wrap;
        }

        &__price,
        &__rent {
            font-size: 22px;
            line-height: 31px;

            @include large-screen {
                font-size: 27px;
            }
        }
    }

    &__right {
        margin-left: auto;

        &__yield-container {
            max-width: 130px;

            @include large-screen {
                max-width: none;
            }

            padding: 16px 37px 15px 34px;

            background-color: var(--color-primary);

            border-radius: $large_border_radius;
        }

        &__yield {
            // line-height: 37px;
            // font-size: 32px;

            line-height: 32px;
            font-size: 24px;

            @include large-screen {
                font-size: 32px;
            }

            color: var(--color-secondary);
        }

        &__yield-label {
            font-size: 12px;
            line-height: 12px;

            color: var(--color-secondary);

            svg {
                transform: translateY(2px);// visual correction for the vertical position
            }
        }
    }
}
