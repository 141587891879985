.bot_button {
    position: fixed;
    background-color: var(--primary);
    box-shadow: var(--box_shadow);
    bottom: 20px;
    right: 20px;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 1000;
    border-radius: 20px;
    overflow: hidden;
    transition: 0.3s;
    cursor: pointer;

    &.clear {
        bottom: 90px;
        background-color: var(--error);
    }

    .unwrap {
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .icon_box {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    i {
        font-size: 28px;
        color: var(--on_primary);
    }

    span {
        font-size: 18px;
        color: var(--on_primary);
    }

    &.c {
        bottom: 20px;
    }

    &.d {
        &:hover,
        &.open {
            width: 160px;
        }
    }
}

.bot_button:hover,
.bot_button.open {
    width: 260px;
}

.bot_button.b:hover,
.bot_button.b.open {
    width: 300px;
}
