

.center-report-form {
    display: flex;
    flex-direction: column;
    background-color: var(--surface);
    border-radius: var(--border_radius);
    padding: 20px;
    width: 500px;
    max-width: 90vw;
    max-height: 90vh;
    overflow-y: scroll;

    h2 {
        margin-bottom: 10px;
    }

    form {
        margin-top: 10px;
        margin-bottom: 20px;
        width: 100%;

        & > * {
            margin-top: 10px;
        }
    }

    .radio-input {
        margin-top: 20px;
    }
}