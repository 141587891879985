.images-upload {

  &__container {
    width: 100%
  }

  &__image-wrapper {
    display: flex;
    flex-direction: column;

    height: 100%;

    font-size: 16px;
  }

  &__drop-button {
    all: unset;

    border-radius: 6px;

    max-width: 20rem;
    height: 100px;

    margin: auto;
    padding: 16px;

    font-size: 16px;

    outline: 1px solid var(--color-medium-grey);

    &.dragging {
      background-color: var(--color-secondary);

      outline: none;
    }

    &:hover {
      background-color: var(--color-secondary-light);

      outline: none;
    }
  }

  &__images-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    margin: auto;

    padding: 16px 0;

    &__item {
      position: relative;

      &__delete-icon {
        height: 24px;
        width: 24px;
      }

      &__delete-button {
        all: unset;

        position: absolute;

        right: 8px;
        top: 8px;

        display: flex;
        align-items: center;
        justify-content: center;

        margin-left: 16px;

        background-color: rgba(255, 255, 255, 0.8);

        outline: 2px solid var(--color-secondary);

        width: 32px;
        height: 32px;

        border-radius: 8px;

        box-shadow: var(--box_shadow);

        svg {
          fill: var(--primary);
        }
      }
    }
  }

  &__image {
    width: 150px;
  }
}