@import "../../../styles/mixins/breakPoints";

.main_view {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    background-color: var(--details_background);

    span.property-title {
        display: block;
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 1.1rem;
        font-weight: bold;
        color: var(--secondary_text);
    }

    .map-container {
        top: 40px;
        //width: 320px;

        width: 22vw;

        position: sticky;
        padding: 10px;

        & > div {
            box-shadow: var(--box_shadow);

            padding: 16px;
        }

        &.with-margin {
            padding-left: 25px;
            padding-right: 25px;
        }

        @media (max-width: 950px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            top: unset;
            position: relative;
            width: 100%;
        }
    }

    .center {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 30px 10px 30px 40px;
        //width: calc(100vw - 620px);
        width: 100%;

        /**
    This is for overload MUI
     */
    //    .Mui-selected {
    //        color: var(--primary) !important;
    //    }
    //
    //    /**
    //This is for overload MUI
    // */
    //    .MuiTabs-indicator {
    //        background-color: var(--color-secondary) !important;
    //    }

        .header {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            margin: 0;
            padding: 0;

            .header_images-container {
                width: 100%;
            }

            .header_image-large {
                position: relative;
                width: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: var(--border_radius);

                    object-fit: cover;
                }

                .show-more {
                    width: fit-content;
                    height: 35px;

                    position: absolute;

                    bottom: 14px;
                    left: 16px;

                    padding: 23px;

                    background: var(--color-secondary);

                    border-radius: 999px;

                    font-style: normal;
                    font-weight: 700;
                    font-size: 13px;
                    line-height: 14.95px;

                    color: #141414;
                }

                .header-flags {
                    display: flex;
                    flex-wrap: wrap;

                    position: absolute;

                    top: 10px;
                    left: 10px;

                    width: 100%;

                    .flag {
                        background-color: rgba(255, 255, 255, 0.8);

                        outline: 2px solid var(--color-secondary);

                        width: 32px;
                        height: 32px;

                        margin-right: 16px;

                        border-radius: 8px;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        box-shadow: var(--box_shadow);

                        z-index: 1;

                        cursor: pointer;

                        svg {
                            fill: var(--primary);

                            path {
                                color: rgba(255, 255, 255, 0.8);
                            }
                        }
                    }
                }
            }

            .header_image_small-container {
                @media (max-width: 950px) {
                    & {
                        display: none;
                    }
                }
            }

            .header_data {
                margin-right: 30px;

                @media (max-width: 950px) {
                    & {
                        margin-right: 0;
                    }
                }

                a {
                    margin-top: 10px;
                }

                button {
                    margin: 10px 0;
                }

                .report-error {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            .header_images_div {
                display: flex;
                flex-direction: row;
                position: relative;

                .spotlight {
                    display: flex;
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    //background-color: var(--surface);

                    background-color: rgba(255, 255, 255, 0.8);

                    outline: 2px solid var(--color-secondary);

                    width: 32px;
                    height: 32px;
                    border-radius: 8px;
                    align-items: center;
                    justify-content: center;
                    box-shadow: var(--box_shadow);
                    z-index: 1;
                    cursor: pointer;

                    svg {
                        fill: var(--primary);
                    }
                }

                & > img {
                    width: 250px;
                    height: 200px;
                    object-fit: cover;
                    border-radius: 4px;
                }

                @media (max-width: 950px) {
                    & > img {
                        border-radius: 0;
                    }
                }

                .header_little_img_div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    height: 200px;
                    margin-left: 10px;

                    img:first-child {
                        margin-bottom: 10px;
                    }

                    img:nth-child(3) {
                        margin-top: 10px;
                    }

                    img {
                        width: 150px;
                        height: 60px;
                        object-fit: cover;
                        border-radius: 4px;
                    }
                }
            }
        }

        .description {
            margin-top: 20px;

            width: 100%;
        }

        .item_wrapper {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            flex-wrap: wrap;
            width: 100%;
            max-height: 550px;

            .item_div {
                display: flex;
                flex-direction: row;
                width: 45%;
                align-items: center;
                justify-content: space-between;
                margin: 5px 2.5%;

                span {
                    font-weight: bold;
                }
            }
        }

        @media (max-width: 1275px) {
            .header {
                flex-direction: column-reverse;

                .header_images_div {
                    width: 100%;
                    margin-bottom: 20px;

                    & > img {
                        min-height: 35vh;
                        width: 100%;
                        object-fit: cover;
                    }

                    .header_little_img_div {
                        display: none;
                    }
                }
            }

            .item_wrapper {
                max-height: unset;

                .item_div {
                    width: 100%;
                    margin: 5px 0;
                }
            }
        }

        @media (max-width: 500px) {
            .header .header_images_div .header_little_img_div {
                display: none;
            }

            .header .header_images_div > img {
                width: 100%;
                height: 40vh;
            }
        }

        @media (max-width: 700px) {
            & {
                max-width: unset;
                width: 100%;
                padding: 40px 30px;
            }
        }

        @media (max-width: 950px) {
            & {
                width: 100%;
                padding: 0;
            }

            .description {
                padding: 30px;
            }

            .item_wrapper {
                padding: 30px;
            }

            .header {
                .header_data {
                    padding: 30px 30px 20px;
                }
            }

            .header .header_images_div > img {
                width: 100%;
                height: 40vh;
            }
        }
    }

    .visibility {
        position: fixed;
        bottom: 20px;
        left: 20px;
        z-index: 1;
        background-color: var(--primary);
        border-radius: var(--border_radius);
        box-shadow: var(--box_shadow);
        padding: 5px;
        cursor: pointer;

        span {
            font-weight: bold;
            font-size: 1.2rem;
            color: var(--on_primary);
        }

        i {
            font-size: 2rem;
            color: var(--on_primary);
        }
    }

    @media (max-width: 950px) {
        & {
            flex-direction: column;
        }
    }
}

.PhotoView-Portal {
    z-index: 10000 !important;
}
