#reset-password {
    display: flex;
    min-height: 80vh;
    width: 100%;
    align-items: center;
    justify-content: center;

    .reset-center {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 400px;

        span {
            margin-bottom: 20px;
        }

        button {
            margin-top: 20px;
        }
    }
}
