
.filters-view {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .filters-center {

        margin-bottom: 50px;
        padding-top: 30px;
        min-height: 100vh;

        .select-filter {
            min-width: 200px;
        }
        
        .filter-block {
            min-width: 600px;
            border-top: 1px solid var(--divider);
            margin-top: 20px;
            padding: 20px 0;

            .margin-top {
                margin-top: 20px;
            }

            h2 {
                margin-bottom: 20px;
            }

            h3 {
                margin-bottom: 10px;

                &.sec {
                    margin-top: 20px;
                }
            }

            .main-input {
                min-width: 200px;
            }
            
            .price-inputs {
                display: flex;
                padding-top: 10px;
                flex: row;

                * {
                    margin-right: 10px;
                }
            }
        }
    }

    @media (max-width: 700px) {
        & {
            padding-top: 40px;
        }
        
        .filters-center {
            width: 90%;
            .filter-block {
                width: 100%;
                min-width: unset;
            }
        }
    }
    #search-btn {
        position: fixed;
        background-color: var(--primary);
        padding: 4px 8px;
        border-radius: var(--border_radius);
        color: var(--on_primary);
        font-size: 1.2rem;
        cursor: pointer;
        bottom: 60px;
        right: 30px;
        box-shadow: var(--box_shadow);
    }
}