.property-element {
    &__icon {
        &__container {
            position: absolute;

            top: 20px;
            right: 10px;

            display: flex;
            justify-content: flex-end;

        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: center;

            margin-left: 16px;

            background-color: rgba(255, 255, 255, 0.8);

            outline: 2px solid var(--color-secondary);

            width: 32px;
            height: 32px;

            border-radius: 8px;

            box-shadow: var(--box_shadow);

            svg {
                fill: var(--primary);
            }
        }
    }
    &__container {
        margin: auto;
        //remove li dot decoration
        list-style-type: none;

        &.loading {
            opacity: 0.3;
        }
    }

    &__link {
        text-decoration: none; // prevent text inside card to be underlined
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // justify-content: flex-start;
        justify-content: space-between;

        position: relative;

        margin: 10px 0;

        border-radius: 10px;
        border: 0.41743px solid rgba(0, 0, 0, 0.3);

        overflow: hidden;

        cursor: pointer;

        transition: 0.3s;

        width: 308.89px;
        //height: 379.94px;
        min-height: 467px;

        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.15em;

        &:hover {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
        }

        &.admin-only {
            border-color: red;
        }

        &__image-container {
            position: relative;

            width: 100%;
            height: 15rem;
            max-height: 15rem;

            overflow: hidden;
        }
    }

    &__image {
        width: 100%;
        height: 100%;

        object-fit: cover;
    }

    &__rent {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;

        top: 19px;

        padding: 10px 20px;

        background: var(--color-secondary-transparent);

        border-radius: 0 843.128px 843.128px 0;

        font-size: 1.2rem;

        color: #141414;
    }

    &__views {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;

        bottom: 19px;

        padding: 5px 8px;

        background: var(--color-divider-transparent);

        border-radius: 0 843.128px 843.128px 0;

        font-size: 0.8rem;

        color: #fff;
    }

    &__peb {
        display: flex;

        position: absolute;

        bottom: 10px;

        right: 10px;

        z-index: 1;

        background-color: #fff;

        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);

        border-radius: 6px;

        padding: 2px 4px;

        img {
            width: 100px;
            height: 100px;

            &.large {
                height: 25px;
                width: 75px;
            }
        }
    }

    &__housing-type {
        color: var(--secondary_text);
    }

    &__location {
        font-weight: 400;

        color: var(--secondary_text);
    }

    &__price {
        display: flex;
        align-items: center;

        font-size: 1.3rem;
        line-height: 1.6em;
    }

    &__square-meter-price {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        text-align: right;

        &--left-align {
            text-align: left;
        }

        span {
            color: var(--secondary_text);
        }

        span:last-child {
            margin-top: 0.2em;
        }
    }

    &__publication-date {
        font-weight: 400;
        text-align: center;

        color: var(--secondary_text);
    }

    &__gross-yield {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }

    &__gross-yield {
        &__percent {
            font-size: 2.4rem;

            color: var(--color-secondary);
        }
        &__text {
            margin-top: 0.75em;
            color: var(--color-secondary);
        }
    }

    &__infos {
        height: 42%;
        width: 100%;

        position: relative;

        z-index: 10;

        box-shadow: 0 3.37589px 12.6596px -7.59575px rgba(176, 176, 176, 0.25);

        padding: 18px 20px 20px;

        box-sizing: border-box;

        display: flex;
        flex-direction: column;

        margin-bottom: auto;

        &__header {
            line-height: 1.25em;
        }

        &__main {
            display: flex;
            flex-direction: row;

            &--column {
                flex-direction: column;
            }

            justify-content: space-between;

            margin-top: 1.5em;
        }

        &__footer {
            margin-top: 1.4em;
        }
    }

    @media (max-width: 850px) {
        .invest_elem {
            width: 100%;
        }
        .invest_elem .data {
            width: 55%;
        }
        .invest_elem img {
            width: 45%;
        }
    }

    @media (max-width: 650px) {
        .invest_elem {
            flex-direction: column-reverse;
        }
        .invest_elem .data {
            width: 100%;
        }
        .invest_elem img {
            width: 100%;
        }

        .invest_elem .viewCount {
            right: unset;
            left: 10px;
        }

        .invest_elem .image_conso {
            bottom: unset;
            top: 10px;
        }
    }
}
