@import "../../styles/mixins/breakPoints";

body {
    padding-top: 69px;

    &.no-nav {
        padding: 0;
    }
}

.main-nav {
    display: flex;
    flex-direction: column;

    @include mobile-and-less {
        padding: 20px;

        &__signup-button {
            margin: 0 16px !important;
        }
    }

    &__top {
        display: flex;
        justify-content: space-between;

        width: 100%;
    }

    &__starter-trial {
        @include mobile-and-less {
            background-color: var(--surface);

            padding: 3px;

            border-radius: 3px;
        }

        &__text {
            color: var(--color-secondary);
            font-weight: bold;

            display: inline-block;

            @include mobile-and-less {
                display: inline;
            }
        }

        &__countdown {
            margin-left: .5em;
        }
    }
}



nav {
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 100%;
    height: 69px;
    top: 0;
    z-index: 200;
    background-color: #ffffff;
    align-items: center;
    justify-content: space-between;
    padding: 5px 40px;
    transition: 0.3s;

    &.hov {
        box-shadow: var(--box_shadow);
    }

    .logo {
        display: flex;
        align-items: center;

        text-decoration: none;
        color: var(--primary);
        font-weight: bold;
        font-size: 1.5rem;
        cursor: pointer;

        &__text {
            display: flex;

            &--start {
                color: var(--color-secondary);
                margin-right: 5px;

                @media (max-width: 700px) {
                    margin-right: 3px;
                }
            }

            &--end {
                color: black;
            }

            @media (max-width: 700px) {
                font-size: 1rem;
            }
        }
    }

    //.logo .green {
    //    color: var(--color-secondary);
    //    margin-right: 5px;
    //}

    .links {
        display: flex;
        align-items: center;
        gap: 35px;
        list-style-type: none;
        a {
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 17px;

            color: #000000;

            text-decoration: none;

            /* Inside auto layout */
            flex: none;
            order: 1;
            flex-grow: 0;
            cursor: pointer;
        }
    }

    .account {
        display: flex;
        flex-direction: row;

        align-items: center;

        .signup {

        }

        .admin-nav {
            display: flex;

            .admin-button {
                margin: 10px 10px 10px 0;
            }
        }
    }

    .drawer-button {
        display: none;
    }

    @media only screen and (max-width: 700px) {
        .drawer-button {
            display: flex;
        }
        & {
            padding: 20px 40px;
            margin: 0;
            position: fixed;
            top: 0;
            width: 100%;
            background-color: var(--surface);

            .links,
            .login {
                display: none;
            }
        }
    }
}

@media (max-width: 700px) {
    body {
        padding-top: 70px;
    }
}

.drawer-content {
    width: 100vw;
    padding: 16px 76px;

    .nav-drawer-button {
        display: block;

        width: 100%;

        margin-bottom: 8px;

        text-align: center;
    }

    .connect-button-container {
        display: flex;

        margin-top: 20px;

        button {
            margin: auto;
        }
    }

    ul {
        li {
            list-style: none;
            margin: 5px 0;
            padding: 5px;

            a {
                text-decoration: none;

                .navigation_elem {
                    display: flex;
                    align-items: center;

                    //background-color: var(--color-light-grey);

                    outline: 2px solid var(--color-light-grey);

                    padding: 1em;

                    border-radius: 999px;

                    text-align: center;

                    margin-bottom: 0.5em;

                    i {
                        color: var(--deactivate);
                        margin-right: 10px;
                        width: 20px;
                        text-align: center;
                    }
                    span {
                        //color: var(--secondary_text);

                        margin: auto;
                    }
                }
            }

            .separator {
                margin-top: 20px;

                h2 {
                    font-size: 1rem;
                    text-transform: uppercase;
                    color: var(--secondary_text);

                    &.main {
                        font-size: 1.1rem;
                    }
                }
            }

            &.active {
                background-color: var(--primary_transparent);
                border-radius: var(--border_radius);
                a {
                    .navigation_elem {
                        i {
                            color: var(--text);
                        }
                        span {
                            color: var(--text);
                        }
                    }
                }
            }
        }
    }
}
