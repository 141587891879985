

.daily-alert {
    margin-top: 50px;
    min-height: 60vh;
    .filters-center {
        .filters-header {
            p {
                width: 800px;
                max-width: 80%;
                margin-top: 20px;
            }
        }

        .alert-activation {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 30px;

            span {
                margin-right: 10px;
                font-weight: bold;
                font-size: 1.2rem;
            }
        }
    }
}