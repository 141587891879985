@import "../../../styles/variables";
@import "../../../styles/mixins/breakPoints";

$image_border-radius: 7px;

.main_view {
    .detail-web {
        display: flex;

        background-color: var(--background);

        width: 100%;

        &__left {
            width: 55%; // control the width of left panel

            @include large-screen {
                width: fit-content;
            }

            padding: 0 0 0 40px;

            .header {
                width: 100%;

                .header_data {
                    width: 100%;

                    margin-top: 11px;

                    &__top {
                        height: 80px;

                        padding: 16px 0 16px;

                        @include large-screen {
                            height: 96px;

                            padding: 29px 0 16px;
                        }

                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        font-weight: 700;

                        h2 {
                            font-size: 24px;
                            line-height: 28px;
                        }

                        &__subtitle {
                            display: flex;

                            p {
                                font-size: 13px;
                                line-height: 15px;
                            }

                            &__location--underline {
                                border-bottom: 1px solid black;

                                margin-bottom: -1px;
                            }

                            &__date {
                                opacity: 50%;

                                margin-left: 4px;
                            }
                        }
                    }

                    .header_images-container {
                        display: flex;

                        height: 38vh;

                        @include large-screen {
                            height: 57vh;
                        }

                        .header_image-large {
                            width: 42vw;

                            @include large-screen {
                                height: 100%;
                            }

                            margin-right: 16px;

                            position: relative;

                            border-radius: $image_border-radius;

                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;

                                object-fit: cover;
                            }

                            .show-more {
                                width: fit-content;
                                height: 35px;

                                position: absolute;

                                bottom: 14px;
                                left: 16px;

                                padding: 23px;

                                background: var(--color-secondary);

                                border-radius: 999px;

                                font-style: normal;
                                font-weight: 700;
                                font-size: 13px;
                                line-height: 14.95px;

                                color: #141414;
                            }

                            .header-flags {
                                display: flex;
                                flex-wrap: wrap;

                                position: absolute;

                                top: 10px;
                                left: 10px;

                                .flag {
                                    background-color: rgba(255, 255, 255, 0.8);

                                    outline: 2px solid var(--color-secondary);

                                    width: 32px;
                                    height: 32px;

                                    margin-right: 16px;

                                    border-radius: 8px;

                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    box-shadow: var(--box_shadow);

                                    z-index: 1;

                                    cursor: pointer;

                                    svg {
                                        fill: var(--primary);

                                        path {
                                            color: rgba(255, 255, 255, 0.8);
                                        }
                                    }
                                }
                            }
                        }

                        .header_image_small-container {
                            width: 15vw;

                            @include large-screen {
                                height: 100%;
                            }

                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            .header_image-small {
                                height: 32%;

                                border-radius: $image_border-radius;

                                overflow: hidden;

                                img {
                                    width: 100%;
                                    height: 100%;

                                    object-fit: cover;
                                }
                            }
                            @media (max-width: 950px) {
                                & {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__right {
            margin-top: 11px;
            margin-left: 15px;

            display: flex;
            flex-direction: column;

            width: 100%;
            height: calc(
                100vh - 69px - 20px
            ); // viewport height - nav height - 20px margin bottom

            top: calc(69px + 11px); // if no positioned, sticky don't work

            position: sticky;

            &__tool-selection {
                width: 100%;

                &__tab-selector {
                    width: 33%;
                }
            }

            &__tool-selection-custo {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
                background-color: #fff;
                border-radius: var(--border_radius);
                width: calc(100% - 10px);
                padding: 5px;
                box-shadow: var(--box_shadow);

                &__tab-selector {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 5px;
                    border-radius: 5px;
                    cursor: pointer;
                    background-color: var(--surface);
                    padding: 5px 10px;
                    width: 33%;
                    height: 100%;

                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 21px;

                    /* identical to box height */
                    text-align: center;

                    color: #141414;

                    &.selected {
                        background-color: var(--color-secondary);
                    }
                   /*  display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    width: calc(33% - 3.33px);
                    cursor: pointer;
                    border-radius: var(--border_radius);
                    padding: 4px 8px;
                    transition: 0.3s;
                    height: 100%;
                    border: 1px solid var(--primary);

                    &.selected {
                        background-color: var(--primary);

                        span {
                            color: var(--on_primary);
                        }
                    } */
                }
            }

            &__tab {
                display: flex;

                // the map have to be also be sized in Map.jsx
                height: calc(
                    100vh - 69px - 20px - 77px
                ); // viewport height - nav height - 20px margin bottom - 59px height of tab-selector
                &__map {
                    width: 51%;

                    margin: 0 13px;

                    position: sticky;

                    border-radius: $large_border_radius;

                    overflow: hidden;
                }
            }
        }
    }
}

.PhotoView-Portal {
    z-index: 10000 !important;
}
