.property-element__flatsharing {
  &__infos {
    height: 42%;
    width: 100%;

    position: relative;

    z-index: 10;

    box-shadow: 0 3.37589px 12.6596px -7.59575px rgba(176, 176, 176, 0.25);

    padding: 18px 20px 20px;

    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    flex:1;
    justify-content: space-between;

    margin-bottom: auto;

    &__bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    &__header {
      line-height: 1.25em;
    }

    &__main {
      display: flex;
      flex-direction: row;

      &--column {
        flex-direction: column;
      }

      justify-content: space-between;

      margin-top: 0;
    }

    &__footer {
      margin-top: 1.4em;
    }

  }

  &__housing-type {
    color: var(--secondary_text);
  }

  &__location {
    font-weight: 400;

    color: var(--secondary_text);
  }

  &__price {
    display: flex;
    align-items: center;

    font-size: 1.3rem;
    line-height: 1.6em;
  }

  &__square-meter-price {

    span {
      color: var(--secondary_text);
    }

    span:last-child {
      margin-top: 0.2em;
    }
  }

  &__flatsharing-infos {
    &__text {
      font-size: 1.2em;
      line-height: 1.2em;
    }
  }

  &__publication-date {
    font-weight: 400;
    text-align: center;

    color: var(--secondary_text);
  }


}