

#not-found-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #fff;
    min-height: 100vh;
    padding-bottom: 80px;

    img {
        width: 40%;
        max-width: 600px;
    }

    .not-found-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 40%;
        max-width: 600px;

        h1 {
            font-size: 2.4rem;
        }

        p {
            margin: 20px 0;
            font-size: 1.2rem;
            color: var(--secondary_text);
        }

        a {
            display: block;
            background-color: var(--primary);
            color: var(--on_primary);
            text-decoration: none;
            border-radius: var(--border_radius);
            padding: 10px 20px;
            font-weight: bold;
            font-size: 1.2rem;
            transition: .3s;
            border: 3px solid var(--primary);

            &:hover {
                background-color: transparent;
                color: var(--primary);
            }
        }
    }

    @media (max-width: 1000px) {
        img {
            width: 55%;
        }
    }

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        min-height: unset;

        img {
            width: 400px;
            max-width: 100%;
        }

        .not-found-text {
            width: 80%;

            h1 {
                font-size: 2rem;
            }

            a {
                font-size: 1rem;
            }
        }
    }
}