.new-by-form {

    &__container {
        max-width: 700px;

        margin: auto;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__upload-widget-container {
        position: fixed;

        width: 100%;
        height: calc(100vh - 69px);

        background-color: var(--transparent-heavy);

        z-index: 10;

        display: flex;
    }

    &__header {
        text-align: center;

        margin : 24px 0;

        width: 90vw;
    }

    &__inputs-group {

        &__container {
            text-align: center;

            h2 {
                margin: 24px 0 16px;

                width: 90vw;
            }
        }

        &__inputs {

            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 24px;
        }
    }

    &__image-upload {
        width: 90vw;
    }
}
