$image_border-radius: 7px;

.video-player {

  &__container {
    max-width: 1000px;
    width: 95vw;
  }

  &__video {
    height: calc(100% - 4px);

    border-radius: $image_border-radius;

    overflow: hidden;

    object-fit: cover;

    video {
      /**
       if no add this, there is a small margin under video
       that truncate border radius.
       */
      display: block;
    }
  }

  &__controls {

    display: flex;
    justify-content: center;
    column-gap: 16px;

    margin-top: 16px;

    &__button {
      height: auto;

      img {
        height: 2rem;

        margin-top: 1px;
      }
    }

  }
}
