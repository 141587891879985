

#admin-market-data-show {
    display: flex;
    flex-direction: column;
    //width: calc(100% - 380px);
    width: 100%;


    .data-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        //justify-content: space-evenly;
        width: 100%;
        height: 100px;
        padding-left: 16px;
        gap: 24px;

        .data-show {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: #fff;
            box-shadow: var(--box_shadow);
            padding: 6px 12px;
            border-radius: var(--border_radius);

            .label {
                color: var(--secondary_text);
            }

            .value {
                font-weight: bold;
                margin-top: 10px;
                font-size: 1.2rem;
            }
        }
    }

    .data-body {
        width: 100%;
        display: flex;
        padding: 20px 20px 40px;
    }
}