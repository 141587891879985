#admin-home {
    padding: 40px;
    min-height: calc(100vh - 40px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    > * + * { margin-top: 10px;}
}

.admin-base-view {
    padding: 80px 40px 40px 40px;

    h1 {
        font-size: 1.6rem;
        margin-bottom: 30px;
    }
}