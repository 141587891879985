@import "../../styles/mixins/breakPoints";

.search-page {
    background: var(--background);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &__mobile-buttons-container {
        @include bigger-than-mobile {
            display: none;
        }

        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;
    }

    &__criteria-button {
        background: none !important;
        color: black !important;

        height: 35px;

        @include mobile-and-less {
            margin: 0;
        }
    }

    &__search-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        overflow: hidden;

        //@include mobile-and-less {
        //    margin-left: 12px;
        //}

        &__label {
            text-align: right;

            margin-right: 6px;

            width: 2em;
            font-size: 14px;
            line-height: 1em;
        }

        .MuiInputBase-root {
            border-radius: 999px;

            background: none;

            margin-left: 8px;

            @include mobile-and-less {
                margin: 0;
            }
        }
    }

    &__select {
        height: 35px;
        //font-size: 0.875rem;
        //letter-spacing: 0.02857em;
        outline: none;

        background-color: var(--surface);

        //max-width: 183px;
        //height: 48.87px;
        //border-radius: 999px;
        min-width: 138px;
        @include mobile-and-less {
            min-width: auto;
            margin: 0;
        }

        text-transform: none;
        letter-spacing: 0;

        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        //line-height: 21px;

        /* identical to box height */
        text-align: center;

        color: #000000;

        @media (max-width: 700px) {
            width: 100%;
            max-width: unset;

            margin: 25px;
        }

        .MuiSelect-select {
            margin-top: 0.22rem;
        }
    }

    .map-button {
        width: min-content;
        background-color: #fff;
        z-index: 10;
        box-shadow: var(--box_shadow);
        border-radius: var(--border_radius);
        padding: 6px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        svg {
            font-size: 1.2rem;
            margin-right: 10px;
        }

        span {
            font-size: 1.2rem;
        }
    }

    &.map {
        min-height: unset;
        height: calc(100vh - 69px);
    }

    .first {
        display: flex;
        justify-self: center;
        justify-content: center;
        flex-direction: column;

        z-index: 2;
        width: 95%;
        align-items: center;
        padding: 25px;
        margin: 1% 0 1% 0;

        background: #ffffff;

        /* shadow-2 */
        box-shadow: 0px 4px 15px -9px rgba(176, 176, 176, 0.25);
        border-radius: 9999px;

        @include mobile-and-less {
            background: none;

            box-shadow: none;

            border-radius: 0;

            //margin-top: 16px;

            width: 100%;

            padding: 20px 20px 0;
            margin-bottom: 0;
        }

        //@media (max-width: 700px) {
        //    border-radius: 50px;
        //
        //    padding: 8px;
        //}

        .filters {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 90px;

            @include mobile-and-less {
                margin-top: 16px;
                overflow: hidden;
            }

            &.open{
                height: auto;
            }

            &.closed{
                @include mobile-and-less {
                    height: 0;
                }
            }

            @media (max-width: 1400px) {
                gap: 10px;
            }
            @media (max-width: 1160px) {
                flex-direction: column;
            }

            @media (max-width: 700px) {
                flex-direction: column;
                gap: 10px;
            }

            #main-filters {
                display: flex;
                gap: 5px;
                @media (max-width: 700px) {
                    flex-direction: column;
                }
            }
        }

        .search__button {
            width: 206px;
            height: 56px;

            background: #141414;
            border-radius: 1220.56px;

            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            text-transform: none;
            letter-spacing: 0;

            /* identical to box height */
            text-align: center;

            color: #ffffff;
        }

        .input_div {
            width: 100%;
            background-color: var(--surface);
            border: 1px solid var(--divider);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 40px;
            border-radius: 8px;
            padding: 4px;

            input {
                border: none;
                outline: none;
                width: 90%;
                max-width: 500px;
                padding: 5px 15px;
                font-size: 16px;
                background-color: var(--surface);
            }
        }

        .search_btn {
            background-color: var(--primary);
            padding: 5px 15px;
            border-radius: 8px;
            cursor: pointer;

            p {
                color: var(--on_primary);
                font-size: 16px;
                font-weight: bold;
            }
        }
    }

    .category-container {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        margin: 10px 0 20px 0;
        width: fit-content;
        border-radius: var(--border_radius);
        border: 1px solid var(--divider);
        padding: 4px;

        span {
            padding: 4px 8px;
            border-radius: var(--border_radius);
            cursor: pointer;
            transition: .3s;
            border: 1px solid transparent;

            &.selected {
                background-color: #fff;
                border-color: var(--primary);
            }
        }
    }

    .advanced-filters {
        width: 65%;
        margin-bottom: 1%;
        @media (max-width: 700px) {
            width: 95%;
        }
    }

    @media (max-width: 700px) {
        .home {
            width: 100%;
            min-width: unset;

            h1 {
                min-width: unset;
            }
        }
    }

    @media (max-width: 500px) {
        & {
            padding-top: 0;
        }
        .home {
            h1 {
                display: none;
            }
        }
    }

    // .main_container {
    //     display: grid;
    //     grid-template-columns: repeat(3, 1fr); /* creates 2 columns with equal width */
    //     grid-template-rows: repeat(2, 1fr); /* creates 2 rows with equal height */
    //     margin-top: 40px;
    //     gap:40px;

    //     list-style-type: none;

    //     @media (max-width: 1000px) {
    //         grid-template-columns: repeat(2, 1fr); /* creates 2 columns with equal width */
    //     }

    //     @media (max-width: 700px) {
    //         display: flex;
    //         flex-direction: column;
    //         width: 100%;
    //         gap:0;
    //     }
    // }

    // .main_container a {
    //     display: flex;
    //     align-items: flex-start;
    //     justify-content: center;
    //     text-decoration: none;
    //     color: var(--text);
    //     transition: .3s;

    //     &.loading {
    //         opacity: .3;
    //     }
    // }

    // @media (max-width: 700px) {
    //     .main_container a {
    //         width: 100%;
    //     }
    // }

    .invest-elem:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    }

    .invest-elem .viewCount {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
        background-color: #fff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
        padding: 2px 4px;
        border-radius: 6px;
    }

    .invest-elem .viewCount span {
        font-weight: bold;
    }

    .pages_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        //@media (max-width: 700px) {
        //    width: 100vw;
        //}

        .pagination_container {
            width: unset;

            margin: auto;
        }
    }

    .sort_div {
        display: flex;

        @media (max-width: 700px) {
            flex-direction: column;
        }


        align-items: center;
        justify-content: center;
        p {
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 17px;

            color: #141414;

            margin-right: 10px;
        }

        .spotlightButton {
            box-sizing: border-box;
            background-color: transparent;

            height: 48.87px;
            min-width: fit-content;

            margin-left: 16px;

            border: 2px solid #000000;
            border-radius: 999px;

            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            text-transform: none;

            color: #000000;
        }

        .sort-select {
            //height: 40px;
            //font-size: 0.875rem;
            //letter-spacing: 0.02857em;
            outline: none;

            background-color: var(--surface);

            //max-width: 183px;
            height: 48.87px;
            border-radius: 999px;
            min-width: 138px;
            text-transform: none;
            letter-spacing: 0;

            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;

            /* identical to box height */
            text-align: center;

            color: #000000;

            @media (max-width: 700px) {
                width: 100%;
                max-width: unset;

                margin: 25px;
            }

            .MuiSelect-select {
                margin-top: 0.22rem;
            }
        }

        //select {
        //    background: #ECECEC;
        //    border-radius: 999px;
        //    padding-left: 17px;
        //    font-size: 16px;
        //    border: none;
        //    width: 200px;
        //    -webkit-appearance: none;
        //    -moz-appearance: none;
        //    appearance: none;
        //    width: 167px;
        //    height: 47px;
        //
        //    font-style: normal;
        //    font-weight: 400;
        //    font-size: 15px;
        //    line-height: 17px;
        //
        //    color: rgba(0, 0, 0, 0.5);
        //
        //}
    }

    @media (max-width: 700px) {
        .pages_header {
            width: 100%;
            flex-direction: column-reverse;
            align-items: flex-start;

            .sort_div {
                margin-left: 5px;

                @media (max-width: 700px) {
                    margin-top: 40px;
                }
            }
        }
    }

    // @media (max-width: 850px) {
    //     .main_container a {
    //         width: 100%;
    //     }
    //     .invest-elem {
    //         width: 100%;
    //     }
    //     .invest-elem .data {
    //         width: 55%;
    //     }
    //     .invest-elem img {
    //         width: 45%;
    //     }

    //     .pages_header {
    //         width: 100%;
    //         margin: 0;
    //     }
    // }

    // @media (max-width: 650px) {
    //     .invest-elem {
    //         flex-direction: column-reverse;
    //     }
    //     .invest-elem .data {
    //         width: 100%;
    //     }
    //     .invest-elem img {
    //         width: 100%;
    //     }

    //     .invest-elem .viewCount {
    //         right: unset;
    //         left: 10px;
    //     }

    //     .invest-elem .image_conso {
    //         bottom: unset;
    //         top: 10px;
    //     }

    //     section {
    //         padding: 0 20px;
    //     }
    // }

    // @media (max-width: 520px) {

    //     & {
    //         padding: 0 20px 20px 20px;
    //     }

    // }
}

.empty-search {
    width: 100%;
    padding: 60px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 40%;
        margin-right: 60px;
    }

    div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h3 {
            font-size: 1.2rem;
            margin-bottom: 10px;
        }
    }

    @media (max-width: 740px) {
        & {
            flex-direction: column;
            text-align: center;

            h3 {
                width: 100%;
            }

            img {
                margin-bottom: 20px;
                width: 80%;
                margin-right: 0;
            }
        }
    }
}
