.my-agency-properties-page {
    background: var(--background);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &__title {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 16px 0;
    }
}
