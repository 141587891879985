.Immovestor-button {

  &--link {
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--link, &--button {
    min-height: 35px;

    padding: 0 16px 1px;
    margin-left: 16px;

    background: var(--color-secondary);
    border: 2px solid var(--color-secondary);
    border-radius: 999px;

    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;

    color: #141414;

    cursor: pointer;

    &--grey {
      background: var(--color-light-grey);
      border: 2px solid var(--color-light-grey);
    }

    &:active {
      transform: scale(0.96);

      // only for touch screen
      //@media (hover: none) {
      //
      //}
    }

    //&:hover {
    //}

    //&:hover:active {
    //}

    &--outlined {
      background-color: white;
    }
  }
}