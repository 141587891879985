#new-by-url {
    padding: 40px;
    min-height: calc(100vh - 40px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    .input-div {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 600px;
        max-width: 90vw;
        margin-bottom: 50px;

        button {
            margin-left: 20px;
        }
    }
}