@import "../../../styles/mixins/breakPoints.scss";

.app-settings {
    &__container {
        width: 50%;

        margin: 24px auto;
        @include mobile-and-less {
            width: 100%;
        }

        h2 {
            margin: 24px 0 16px;
        }

        h3 {
            margin: 16px 0 8px;
        }
    }

    &__input {
        margin: 16px 8px 0 0 !important;

        width: 45%;
    }
}
