

#admin-market-data-filters {
    display: flex;
    flex-direction: column;
    //height: 100vh;
    //overflow-y: scroll;
    width: 380px;
    padding: 20px;
    border-right: 1px solid var(--divider);
    box-shadow: var(--box_shadow);
    background-color: #fff;

    .filter-block {
        display: flex;
        flex-direction: column;
        margin-top: 12px;
        margin-bottom: 0;

        &:first-child {
            margin-top: 0;
        }
        
        h2 {
            font-size: 1.2rem;
            margin-bottom: 4px;
        }

        .explain {
            margin-bottom: 10px;

            p {
                font-size: .9rem;
            }
        }
    }
}