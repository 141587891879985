@import "../../styles/variables";

.yield-calculator {
    max-height: 100%;

    width: 90%;
    height: fit-content;

    display: flex;
    flex-direction: column;

    background-color: white;

    border-radius: $large_border_radius;

    padding: 26px 18px;

    overflow-y: scroll;

    h1 {
        margin-bottom: 16px;
    }
}

.demographic-view {
    max-height: 100%;

    width: 49%;
    height: fit-content;

    display: flex;
    flex-direction: column;

    background-color: white;

    border-radius: $large_border_radius;

    padding: 26px 18px;

    @media (max-width: 950px) {
        width: calc(100% - 40px);
        margin: 20px;
    }

    h2 {
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;

        text-align: center;

        flex: 1;
    }

    &__content {
        flex: 1;

        overflow-x: hidden;
        overflow-y: auto;

        &__list {
            margin-top: 13px;
        }

        &__item {
            display: flex;
            flex-direction: column;

            h3 {
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
            }

            p {
                font-size: 14px;
                font-weight: 700;
                line-height: 16px;

                color: var(--color-secondary);

                margin: 5px 0 20px;
            }
        }

        &__item:last-child {
            p {
                margin-bottom: 0;
            }
        }

        &__button {
            margin: 20px auto;
        }

        &__ask-log {
            display: flex;
            flex-direction: column;

            p {
                margin-top: 13px;
            }
        }
    }
}
