#profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    padding-top: 60px;

    .main-view-center {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 700px;
        max-width: 90vw;

        .subscription {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            p {
                margin: 10px 0;
            }
        }

        .settings-block {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            padding: 20px 0;
            margin-top: 30px;
            border-top: 1px solid var(--divider);

            .account-type {
                padding: 10px 20px;

                border-radius: var(--border_radius);

                background-color: var(--color-secondary);

                box-shadow: var(--box_shadow);

                span {
                    font-weight: 500;
                    color: black;
                }

                &.investor {
                    background-color: #e74c3c;

                    font-weight: bold;

                    color: var(--on_primary);
                }

                &.agent {
                    background-color: #5499c7;

                    font-weight: bold;

                    color: var(--on_primary);
                }
            }

            form {
                display: flex;
                flex-direction: column;

                > *:first-child {
                    margin-bottom: 20px;
                }
            }

            > * {
                margin-bottom: 20px;
            }

            .confirm-div {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                width: 100%;

                button:first-child {
                    margin-right: 10px;
                }
            }
        }
    }
}
