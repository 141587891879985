

#new-by-cron {
    padding: 80px 20px ;
    
    .new-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .empty {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 120px 0;

        p {
            font-size: 1.2rem;
        }

        button {
            display:flex;
            background-color: white;
            border: 1px solid #000;
            padding: 10px 20px;
            border-radius: 5px;
            margin-top: 30px;
            font-size: 18px;
            cursor: pointer;

            &:hover {
                background-color: var(--color-secondary);
                border-color: var(--color-secondary);
            }

            &:disabled {
                background-color: #ccc;
                border-color: #ccc;
                color: gray;
                cursor: default;
            }
        }
    }
}